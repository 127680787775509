<template>
	<section
		:id="slug"
		:class="{
			mobile: mobileMode,
			desktop: !mobileMode,
			standalone: standalone
		}"
		class="page-team"
	>
		<header class="page-header">
			<h1 class="page-title" v-html="title" />
		</header>

		<section class="page-description" v-html="page.description" />

		<div
			:style="{
				'grid-template-columns': new Array(columnsCount).fill('1fr').join(' '),
				'--columns': columnsCount
			}"
			class="columns-wrapper"
		>
			<template v-for="(memberData, index) in crew">
				<article
					:key="`${index}-member`"
					:style="{
						'--cell-width': 1
					}"
					class="team-member"
				>
					<header class="member-header">
						<img
							v-if="memberData.image"
							:src="getImageUrl(memberData.image)"
							alt=".."
							class="member-picture"
						/>
						<div class="member-name-wrapper">
							<h2 class="member-name">
								<a v-if="memberData.link" :href="memberData.link" target="_blank">{{
									memberData.name
								}}</a>
								<template v-else>{{ memberData.name }}</template>
							</h2>
							<span class="member-function">{{ memberData.position }}</span>
						</div>
					</header>
					<section class="member-description" v-html="memberData.description" />
				</article>
				<span v-if="memberData.separator" :key="`${index}-separator`" class="separator" />
			</template>
		</div>
	</section>
</template>

<script>
import { resolveRelativeStaticURL } from "@/utilites.js";
import subpageMixin from "./subpage-mixin";

export default {
	mixins: [
		// adds `pageData` prop and `page` computed value
		subpageMixin
	],

	props: {
		// `pageData` via subpage mixin
	},

	computed: {
		// [page, slug] via subpage mixin

		columnsCount() {
			// based on viewport width
			// let pagePadding = this.mobileMode ? 25 : 40;
			// let gridGap = 60;
			// let reference_col_width = 400;
			// return Math.floor((this.windowWidth - 2 * pagePadding + gridGap) / (reference_col_width + gridGap));

			return this.mobileMode ? 1 : 3;
		},

		crew() {
			return this.page.team_members
				.slice()
				.sort((aMember, bMember) => aMember.id - bMember.id);
		}
	},

	methods: {
		getImageUrl(image) {
			return resolveRelativeStaticURL(image.meta.download_url);
		}
	}
};
</script>

<style lang="sass" scoped>
.page-team
	@extend %page !optional

	&.mobile
		@extend %page-mobile

		&.standalone
			@extend %page-mobile-standalone

	&.desktop
		@extend %page-desktop

		&.standalone
			@extend %page-desktop-standalone


.page-header
	@extend %page-header !optional

	.mobile &
		@extend %page-header-mobile

	.mobile.standalone &
		@extend %page-header-mobile-standalone

	.desktop &
		@extend %page-header-desktop

	.desktop.standalone &
		@extend %page-header-desktop-standalone


.page-title
	@extend %page-title

	.mobile &
		@extend %page-title-mobile

	.desktop &
		@extend %page-title-desktop


.page-description
	::v-deep > p
		.desktop &
			width: calc((100% - 10rem) / 3 * 2)

	.mobile &
		margin-bottom: 25px

	.desktop &
		margin-bottom: 40px

	// .separator
	&::after
		content: ""
		display: block
		border-bottom: 1px solid #dddddd
		width: 100%

		.mobile &
			margin-top: 25px

		.desktop &
			margin-top: 40px




.columns-wrapper
	--columns: 3
	display: grid
	grid-template-columns: repeat(var(--columns, 3), 1fr)

	// grid-column-gap: rslv($pad__x)
	.desktop &
		grid-column-gap: rslv($pad__x * 2)
		grid-row-gap: rslv($pad__x)

	.mobile &
		grid-column-gap: rslv($pad__x__mob * 2)
		grid-row-gap: rslv($pad__x__mob)

	.separator
		display: block
		grid-column-end: span var(--columns, 3)
		align-self: center
		border-bottom: 1px solid #dddddd

	& > *
		--cell-width: 1
		grid-column-end: span var(--cell-width, 1)

		//.desktop &
			// max-width: calc(100vw - #{rslv($pad__x)} * 2)

			// &:not(:last-child)
			// 	margin: 0 rslv($p-std__column-gap)
			// 	margin-left: 0

		//.mobile &
			// max-width: calc(100vw - #{rslv($pad__x__mob)} * 2)

			// &:not(:last-child)
			// 	margin-bottom: rslv($p-std__column-gap__mob)

.team-member
	display: flex
	flex-direction: column
	align-items: stretch
	justify-content: flex-start

	&:not(:last-child)
		.mobile &
			margin-bottom: 50px

		.desktop &
			// margin-bottom: 60px

.member-header
	display: flex
	flex-direction: row
	align-items: stretch
	justify-content: flex-start

	.mobile &
		margin-bottom: 25px

	.desktop &
		margin-bottom: 40px

.member-picture
	display: inline-block
	object-fit: cover
	height: var(--member-picture-size, 100px)
	width: var(--member-picture-size, 100px)

	.mobile &
		--member-picture-size: 100px
		margin-right: 25px

	.desktop &
		--member-picture-size: 120px
		margin-right: 40px

.member-name-wrapper
	display: inline-flex
	flex-direction: column
	align-items: flex-start

	.mobile &
	.desktop &

.member-name
	display: block
	margin: 0

	.mobile &
		@extend %h2-mobile
		margin-top: -.5em

	.desktop &
		@extend %h2-desktop
		margin-top: -.375em

	& > a
		text-decoration-color: currentColor

.member-function
	display: block
	margin-top: auto

	.mobile &
		@extend %p-mobile
		margin-bottom: -.45em

	.desktop &
		@extend %small-regular-desktop
		margin-bottom: -.3em

// .member-link
// 	color: inherit
// 	.mobile &
// 		@extend %p-mobile
// 		&:not(:first-child)
// 			margin-top: 34/18 * 1em

// 	.desktop &
// 		@extend %p-desktop
// 		&:not(:first-child)
// 			margin-top: 25/14 * 1em

.member-description
	margin-bottom: auto

.page-description,
.member-description
	& ::v-deep p
		// text-indent: 2em
		margin: 0
		padding: 0
		// white-space: pre-wrap // preserves all \s

		.mobile &
			@extend %p-mobile
			margin-bottom: 25/14 * 1em

		.desktop &
			@extend %p-desktop
			margin-bottom: 34/18 * 1em

		&:last-child
			margin-bottom: 0

	& ::v-deep nobr
		white-space: nowrap


	& ::v-deep .margined_links a
		margin-right: 1.5em
</style>
