var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('section',{staticClass:"page-team",class:{
		mobile: _vm.mobileMode,
		desktop: !_vm.mobileMode,
		standalone: _vm.standalone
	},attrs:{"id":_vm.slug}},[_c('header',{staticClass:"page-header"},[_c('h1',{staticClass:"page-title",domProps:{"innerHTML":_vm._s(_vm.title)}})]),_c('section',{staticClass:"page-description",domProps:{"innerHTML":_vm._s(_vm.page.description)}}),_c('div',{staticClass:"columns-wrapper",style:({
			'grid-template-columns': new Array(_vm.columnsCount).fill('1fr').join(' '),
			'--columns': _vm.columnsCount
		})},[_vm._l((_vm.crew),function(memberData,index){return [_c('article',{key:(index + "-member"),staticClass:"team-member",style:({
					'--cell-width': 1
				})},[_c('header',{staticClass:"member-header"},[(memberData.image)?_c('img',{staticClass:"member-picture",attrs:{"src":_vm.getImageUrl(memberData.image),"alt":".."}}):_vm._e(),_c('div',{staticClass:"member-name-wrapper"},[_c('h2',{staticClass:"member-name"},[(memberData.link)?_c('a',{attrs:{"href":memberData.link,"target":"_blank"}},[_vm._v(_vm._s(memberData.name))]):[_vm._v(_vm._s(memberData.name))]],2),_c('span',{staticClass:"member-function"},[_vm._v(_vm._s(memberData.position))])])]),_c('section',{staticClass:"member-description",domProps:{"innerHTML":_vm._s(memberData.description)}})]),(memberData.separator)?_c('span',{key:(index + "-separator"),staticClass:"separator"}):_vm._e()]})],2)])}
var staticRenderFns = []

export { render, staticRenderFns }